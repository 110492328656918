import React from 'react'
import {graphql,StaticQuery, Link} from "gatsby"
import Layout from '../components/layout'
import Container from '../components/container'
import Post from "../components/post"
import Highlight from '../components/highlight'

const PartnerProgram = () => (
    <Layout>
      
        <div className="breadcrumb_top">
            <div className="row justify-content-center align-items-center">
                <div className="text-center">
                    <h2>Join Optinly's Recursive Commission Payout Program</h2>
                    <p><Highlight>Earn 30% Recurring Commission</Highlight> for One Year On Every Sale You Make</p>
                    <p><a className="btn btn-action btn-lg mx-auto" href="https://retainful.tapfiliate.com/publisher/signup/optinly-partner-program/" target="_blank" rel="noopener">Become our Partner</a>
                        <br/><Link className="btn btn-link mt-2" target="_blank" to="/marketing-material" style={{textDecoration: 'underline'}}>Take a Look at Our Marketing Asset!</Link></p>
                </div>
            </div>
        </div>

        <Container>
            <div className="legal-info">
                <StaticQuery
                    query={PartnerProgramQuery}
                    render={data => {
                    return (
                        <Post
                            excerptData={data.markdownRemark.htmlAst}
                            title={data.markdownRemark.frontmatter.title}
                            description={data.markdownRemark.frontmatter.description}
                        />
                    )
                    }}
                />
            </div>
        </Container>
    </Layout>
)

const PartnerProgramQuery = graphql`
query PartnerProgramQuery {
  markdownRemark(frontmatter: {path: {eq: "/partner-program"}}){
    htmlAst
    frontmatter{
      title
      description
      path
    }
  }
}
`

export default PartnerProgram
